<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="credentials" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> Credentials</h5>
            </div>
            <div class="page-title-right">
              <button type="button" class="btn btn-primary btn-rounded" @click="editCredential('edit')"><i class="fal fa-pencil mr-2"></i>Add Credential</button>
            </div>
          </div>

          <div class="widget-list">

            <div v-for="(c) in credentials" :key="c.id" class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="row">
                    <div class="col">
                      <div class="clearfix">
                        <div class="float-left pr-5 pb-2 pb-sm-0" style="width: 200px;">
                          <img v-if="companyToURL(c.name)" :src="`//logo.clearbit.com/${companyToURL(c.name)}`" :title="c.name" style="max-height: 40px;">
                          <h5 v-else class="m-0 pt-2">{{ c.name }}</h5>
                        </div>
                        <div class="float-left">
                          <a v-if="$auth.profile.site_admin" href="javascript:void(0)" class="no-link password" @click="copyAccount(c)"><span v-if="c.description"><strong>{{ c.description }}</strong> &bull; </span><span :class="!c.description ? 'font-weight-bold' : ''">{{ c.account }}</span><i class="fal fa-copy ml-2"></i></a>
                          <span v-else><span v-if="c.description"><strong>{{ c.description }}</strong> &bull; </span><span :class="!c.description ? 'font-weight-bold' : ''">{{ c.account }}</span> <span v-if="c.totp" class="badge badge-primary"><span class="full">2FA</span></span></span>
                          <br>
                          Given access {{ $datefns.fromNow(c.created_at) }} <span v-if="c.expires_at">&bull; Expires in {{ $datefns.fromNow(c.expires_at) }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="$auth.profile.site_admin" class="col-1 pt-2 d-none d-sm-block">
                      <button type="button" class="btn btn-link p-0 password" @click="copySecret(c)">****<i class="fal fa-copy ml-2"></i></button>
                    </div>
                    <div v-if="$auth.profile.site_admin" class="col-1 pt-2 d-none d-sm-block">
                      <button v-if="c.totp" type="button" class="btn btn-link p-0 password" @click="copyTOTPCode(c)"><span class="badge badge-primary"><span class="full">2FA</span></span><i class="fal fa-copy ml-2"></i></button>
                    </div>
                    <div class="col-1 text-right pt-2">
                      <b-dropdown toggle-class="py-0 px-3" variant="link" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                        <template v-slot:button-content><i class="fal fa-lg fa-ellipsis-v text-muted"></i></template>
                        <div class="card">
                          <header class="card-header d-flex justify-content-between">
                            <i class="fal fa-cog text-primary" aria-hidden="true"></i>
                            <span class="heading-font-family flex-1 text-center fw-400"><span class="d-none d-sm-inline">Settings</span><span class="d-sm-none">Credential</span></span>
                          </header>
                          <div class="card-body list-unstyled dropdown-list">
                            <button v-if="$auth.profile.site_admin" type="button" class="dropdown-item d-sm-none" @click="copySecret(c)">Copy Password</button>
                            <button v-if="$auth.profile.site_admin" type="button" class="dropdown-item d-sm-none" @click="copyTOTPCode(c)">Copy 2FA Code</button>
                            <button type="button" class="dropdown-item" @click="editCredential('edit', c)">Edit <span class="d-sm-none">Settings</span></button>
                            <div class="dropdown-divider"></div>
                            <button type="button" class="dropdown-item text-danger" @click="editCredential('delete', c)"><i class="fal fa-times mr-2"></i>Delete</button>
                          </div>
                        </div>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="credentials.length === 0" class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="text-center py-4">
                    <h4 class="my-0"><p><i class="fal fa-key fa-5x text-stroke-5 text-icon-gray"></i></p><p>No credentials found for this project</p></h4>
                    <button type="button" class="btn btn-primary btn-rounded" @click="editCredential('edit')"><i class="fal fa-pencil mr-2"></i>Add Credential</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <b-modal id="editmodal" :title="form.mode === 'update' ? `Edit ${form.title}` : 'Add Credential'" centered>
            <div class="form-group">
              <label class="col-form-label">Service</label>
              <input type="text" class="form-control" v-model="form.name" maxlength="254">
            </div>
            <div class="form-group">
              <label class="col-form-label">Description</label>
              <input type="text" class="form-control" v-model="form.description" maxlength="254">
            </div>
            <div class="form-group">
              <label class="col-form-label">Username</label>
              <input type="text" class="form-control" v-model="form.account" maxlength="254">
            </div>
            <div class="form-group">
              <label class="col-form-label">Password</label>
              <input type="password" class="form-control" v-model="form.secret" :placeholder="form.mode === 'update' ? '****' : ''">
            </div>
            <div class="form-group">
              <label class="col-form-label">Expires</label>
              <b-form-datepicker v-model="form.expires_at" locale="en-US" hide-header no-highlight-today value-as-date reset-button reset-button-variant="outline-primary" label-reset-button="Clear" label-help="" :date-disabled-fn="disablePastDates" menu-class="dropdown-card w-dropdown-card-medium mt-2"></b-form-datepicker>
            </div>
            <div class="form-group">
              <div class="checkbox checkbox-primary">
                <label><input type="checkbox" v-model="form.totp"><span class="label-text">Enable Two Factor Authentication <i class="fal fa-info-circle fa-lg text-primary" v-b-tooltip.hover title="Compatible with Time-based One Time Passwords (TOTP). Uses Google Authenticator settings."></i></span></label>
              </div>
              <div v-if="form.totp">
                <label class="col-form-label">OTP Secret</label>
                <input type="text" class="form-control" v-model="form.totp_secret" maxlength="254" :placeholder="form.mode === 'update' ? '****' : ''">
              </div>
            </div>
            <template v-slot:modal-footer>
              <button type="button" class="btn btn-primary btn-rounded mr-auto" @click="saveCredential()">Save Changes</button>
            </template>
          </b-modal>
          <b-modal id="deletemodal" title="Delete Credential?" centered>
            This credential will be removed from the project.
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteCredential()">Delete</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, TooltipPlugin, DropdownPlugin, FormDatepickerPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormDatepickerPlugin);

export default {
  data() {
    return {
      project: {},
      credentials: [],
      form: {
        mode: 'create',
        id: '',
        name: '',
        account: '',
        description: '',
        secret: '',
        totp: false,
        totp_secret: '',
        expires_at: null,
      },
    };
  },
  methods: {
    companyToURL(name) {
      switch (name.toLowerCase()) {
        case 'shopify':
        case 'asana':
        case 'microsoft':
        case 'google':
        case 'mailchimp':
        case 'klaviyo':
        case 'facebook':
        case 'instagram':
        case 'paypal':
        case 'stripe':
        case 'zendesk':
        case 'salesforce':
        case 'constantcontact':
        case 'github':
        case 'gitlab':
        case 'kinsta':
        case 'wpengine':
          return `${name}.com`;
        case 'constant contact':
          return 'constantcontact.com';
        case 'wordpress':
          return 'wordpress.org';
        default:
          return null;
      }
    },
    disablePastDates(ymd, date) {
      if (date < new Date()) {
        return true;
      }
      return false;
    },
    copyAccount(c) {
      navigator.clipboard.writeText(c.account);
      toast.icon(this, 'success', 'fa-check');
    },
    copySecret(c) {
      this.$api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/credentials/${c.id}`)
        .then((res) => {
          navigator.clipboard.writeText(res.data.secret);
          toast.icon(this, 'success', 'fa-check');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    copyTOTPCode(c) {
      this.$api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/credentials/${c.id}/totp`)
        .then((res) => {
          navigator.clipboard.writeText(res.data.code);
          toast.icon(this, 'success', 'fa-check');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    saveCredential() {
      if (this.form.secret === '') {
        delete this.form.secret;
      }
      let resource;
      if (this.form.mode === 'update') {
        resource = this.$api.put(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/credentials/${this.form.id}`, this.form);
      } else {
        resource = this.$api.post(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/credentials`, this.form);
      }
      resource
        .then((res) => {
          this.$bvModal.hide('editmodal');
          if (this.form.mode === 'update') {
            for (let i = 0; i < this.credentials.length; i += 1) {
              if (this.credentials[i].id === this.form.id) {
                this.credentials[i].name = res.data.name;
                this.credentials[i].description = res.data.description;
                if (res.data.account) {
                  this.credentials[i].account = res.data.account;
                } else {
                  delete this.credentials[i].account;
                }
                this.credentials[i].totp = res.data.totp;
                this.credentials[i].expires_at = res.data.expires_at;
                break;
              }
            }
          } else {
            this.credentials.push(res.data);
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    deleteCredential() {
      this.$api.delete(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/credentials/${this.form.id}`)
        .then(() => {
          this.$bvModal.hide('deletemodal');
          for (let i = 0; i < this.credentials.length; i += 1) {
            if (this.credentials[i].id === this.form.id) {
              this.credentials.splice(i, 1);
              break;
            }
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    editCredential(modal, c) {
      if (!c) {
        this.form.mode = 'create';
        this.form.id = '';
        this.form.name = '';
        this.form.title = this.form.name;
        this.form.account = '';
        this.form.description = '';
        this.form.secret = '';
        this.form.totp = false;
        this.form.expires_at = null;
      } else {
        this.form.mode = 'update';
        this.form.id = c.id;
        this.form.name = c.name;
        this.form.title = this.form.name;
        this.form.account = c.account;
        this.form.description = c.description;
        this.form.secret = c.secret;
        this.form.totp = c.totp;
        this.form.expires_at = c.expires_at ? new Date(c.expires_at) : null;
      }
      this.$bvModal.show(`${modal}modal`);
    },
  },
  async mounted() {
    try {
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      // check if credentials is enabled
      let c = false;
      const m = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/members`)).data;
      for (let i = 0; i < m.length; i += 1) {
        if (m[i].id === this.$auth.profile.id) {
          c = m[i].enable_credentials;
          break;
        }
      }
      if (!c && !this.$auth.profile.site_admin) {
        throw new Error(404);
      }
      this.credentials = (await this.$api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/credentials`)).data;
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>

<style scoped>
.password i { color: #ffffff; }
.password:hover i { color: #888888; }
label .label-text { color: #555555; font-weight: bold; }
</style>
